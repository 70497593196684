import React from 'react';
import { Accordion, ListGroup, Form, Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const LayerSetting = ({ layer, selectedLayer, setSelectedLayer, layerState, setLayerState, category }) => {

    const layer_change = (e) => {
        if (selectedLayer.includes(e.target.value)) {
            setSelectedLayer(
                selectedLayer.filter((checkedValue) => checkedValue !== e.target.value)
            );
        } else {
            setSelectedLayer([...selectedLayer, e.target.value]);
        }
    };

    const categorizedLayers = category.map((category) => {
        // category.typeが"layer"でない場合はnullを返してスキップ
        if (category.type !== "layer") return null;

        const categoryLayers = layer.filter((layer) => layer.category === category.id);
        return { ...category, layers: categoryLayers };
    }).filter(Boolean); // nullの値を除去

    return (
        <div id={`${layerState ? 'layersetting_show' : 'layersetting_close'}`}>
            <Accordion>
                {categorizedLayers.map((category, index) => (
                    <Accordion.Item eventKey={index.toString()} key={category.id}>
                        <Accordion.Header>{category.name}</Accordion.Header>
                        <Accordion.Body>
                            {category.layers.length > 0 ? (
                                <ListGroup>
                                    {category.layers.map((layer) => (
                                        <ListGroup.Item>
                                            <Form.Check type="checkbox" key={layer.id} id={layer.id} label={layer.name} value={layer.id} onChange={layer_change} checked={selectedLayer.includes(layer.id)} /><Badge bg="primary">{layer.note}</Badge>
                                        </ ListGroup.Item>
                                    ))}
                                </ListGroup>
                            ) : (
                                <p>レイヤがありません</p>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <input type="checkbox" id="layerclose" className="close" onClick={() => { setLayerState(layerState => layerState = false) }} /><label htmlFor="layerclose" className="close_button">閉じる</label>
        </div>
    )
}

export default LayerSetting;