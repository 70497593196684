import './App.css';
import React, { useState } from 'react'
import MapView from './map/mapview';
import MapControles from './map/mapcontroles';
import BaseMapSetting from './map/basemapsetting';
import LayerSetting from './map/layersetting';
import StudySetting from './map/studysetting';
import basemap from './list/basemap.json';
import layer from './list/layer.json';
import category from './list/category.json';

function App() {

  const [selectedBasemap, setSelectedBasemap] = useState("0");
  const [basemapState, setbasemapState] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState([]);
  const [layerState, setLayerState] = useState(false);
  const [studyState, setStudyState] = useState(false);

  return (
    <>
      <MapView
        basemap={basemap}
        selectedBasemap={selectedBasemap}
        setSelectedBasemap={setSelectedBasemap}
        layer={layer}
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
      />
      <MapControles
        basemapState={basemapState}
        setbasemapState={setbasemapState}
        layerState={layerState}
        setLayerState={setLayerState}
        studyState={studyState}
        setStudyState={setStudyState}
      />
      <BaseMapSetting
        basemap={basemap}
        selectedBasemap={selectedBasemap}
        setSelectedBasemap={setSelectedBasemap}
        basemapState={basemapState}
        setbasemapState={setbasemapState}
      />
      <LayerSetting
        layer={layer}
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
        layerState={layerState}
        setLayerState={setLayerState}
        category={category}
      />
      <StudySetting
        layer={layer}
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
        studyState={studyState}
        setStudyState={setStudyState}
        category={category}
      />
    </>
  );
}

export default App;