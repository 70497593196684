import '../App.css';
import 'leaflet/dist/leaflet.css';
import React from 'react'
import { MapContainer, TileLayer, LayersControl, Marker, CircleMarker, Popup, Tooltip } from 'react-leaflet'
import Leaflet from "leaflet";

const Layer = ({ layer, selectedLayer, setSelectedLayer }) => {

  const layers = layer
    .filter(layer => selectedLayer.includes(layer.id)) // 一致する要素のみ抽出
    .map(layer => (
      <TileLayer attribution={layer.attribution} url={layer.url} key={layer.id} /> // 一致する要素を使って処理を実行
    ));

  return (
    layers
  )
}

export default Layer;